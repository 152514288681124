import { browserHistory } from "react-router";
import constants from "../constants";
import axios from "axios";
const api = axios.create({
  baseURL: constants.apiUrl,
  headers: {
    Pragma: "no-cache",
  },
});

/**
 * This function will provide a generic catch for all axios calls.  Specifically we're looking
 * to catch 401 invalid session errors to redirect to login
 */
api.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    const data = error.response && error.response.data,
      status = data && data.statusCode,
      msg = data && data.message;
    if (
      data &&
      status === 401 &&
      msg !== "Incorrect Password" &&
      msg !== "Login Expired" &&
      msg !== "Invalid Token" &&
      msg !== "Token Expired"
    ) {
      browserHistory.push("/");
    } else {
      return Promise.reject(error);
    }
  }
);

const authApi = {
  getOAuthToken(userId) {
    return api.get(`/auth/oauth?userId=${encodeURIComponent(userId)}`, {
      withCredentials: true,
    });
  },
  postSetPicturePassword(username, password) {
    return api.put("/users/set-picture-password", {
      username,
      password,
    });
  },
  getAllPicturePasswords(username) {
    return api.get(
      `/users/picture-password/all?username=${encodeURIComponent(username)}`
    );
  },
  loginWithUsername(username) {
    return api.get(`/users/picture?username=${encodeURIComponent(username)}`);
  },
  loginWithPassword(username, password) {
    return api
      .post("/users/login", {
        username: username,
        password: password,
      })
      .then((response) => {
        return response;
      });
  },
  getTeachingResources(strand) {
    let url =
      "/teachingResources?page=1&featured=true&per_page=20&strand=" +
      strand +
      "&random=true&lite=" +
      localStorage.is_lite;

    return api
      .get(url, {
        withCredentials: true,
      })
      .then((response) => {
        return response.data;
      });
  },
  submitELearningAnswer(bookId, questionId, answerId) {
    let url = `/books/e-learner/answer`;
    return api
      .post(
        url,
        {
          question_id: questionId,
          answer_id: answerId,
        },
        {
          withCredentials: true,
        }
      )
      .then((response) => {
        return response.data;
      });
  },
  getELearningData(book) {
    let url = `/books/${book}/e-learner`;
    return api
      .get(url, {
        withCredentials: true,
      })
      .then((response) => {
        return response.data;
      });
  },
  getResources(pageNumber, strand, query, isBackButtonPressed) {
    let url = "";

    if (isBackButtonPressed === undefined) {
      isBackButtonPressed = false;
    }
    if (query) {
      url =
        "teachingResources/filter?page=" +
        pageNumber +
        "&per_page=100&strand=" +
        strand +
        (query ? query : "") +
        "&lite=" +
        localStorage.is_lite +
        "&isBackbuttonPressed=" +
        isBackButtonPressed;
    } else {
      url =
        "/teachingResources?page=" +
        pageNumber +
        "&per_page=100&strand=" +
        strand +
        "&lite=" +
        localStorage.is_lite +
        "&isBackbuttonPressed=" +
        isBackButtonPressed;
    }
    return api
      .get(url, {
        withCredentials: true,
      })
      .then((response) => {
        return response?.data;
      });
  },
  getFeaturedUnits(strand) {
    let url = "/units?page=1&per_page=20&featured=true&unit_type=" + strand;
    return api
      .get(url, {
        withCredentials: true,
      })
      .then((response) => {
        return response.data;
      });
  },
  getElearningUnits(strand, query, isBackButtonPressed) {
    let url = "";
    strand = strand === 5 ? 4 : strand;
    if (query) {
      url =
        "/units/filter?page=1&per_page=20&elearning=true&unit_type=" +
        strand +
        (query ? query : "") +
        "&isBackbuttonPressed=" +
        isBackButtonPressed;
    } else {
      url = "/units?page=1&per_page=20&elearning=true&unit_type=" + strand;
    }
    return api
      .get(url, {
        withCredentials: true,
      })
      .then((response) => {
        return response.data;
      });
  },
  getFoundationalUnits(strand, query, isBackButtonPressed) {
    let url = "";
    if (query) {
      url =
        "/units/filter?page=1&per_page=20&foundational=true&unit_type=" +
        strand +
        (query ? query : "") +
        "&isBackbuttonPressed=" +
        isBackButtonPressed;
    } else {
      url = "/units?page=1&per_page=20&foundational=true&unit_type=" + strand;
    }
    return api
      .get(url, {
        withCredentials: true,
      })
      .then((response) => {
        return response.data;
      });
  },
  validateElearningUnits(id) {
    let url = `/units/${id}/unitElearningStatus`;
    return api
      .get(url, {
        withCredentials: true,
      })
      .then((response) => {
        return response.data;
      });
  },

  getUnits(pageNumber, strand, query, isBackButtonPressed) {
    let url = "";
    if (isBackButtonPressed === undefined) {
      isBackButtonPressed = false;
    }

    if (query) {
      if (strand === 5) {
        url =
          "units/filter?page=" +
          pageNumber +
          "&per_page=20&elearning=true&unit_type=4" +
          (query ? query : "") +
          "&isBackbuttonPressed=" +
          isBackButtonPressed;
      } else if (strand === 4) {
        url =
          "units/filter?page=" +
          pageNumber +
          "&per_page=20&foundational=true&unit_type=4" +
          (query ? query : "") +
          "&isBackbuttonPressed=" +
          isBackButtonPressed;
      } else {
        url =
          "units/filter?page=" +
          pageNumber +
          "&per_page=24&unit_type=" +
          strand +
          (query ? query : "") +
          "&isBackbuttonPressed=" +
          isBackButtonPressed;
      }
    } else {
      if (strand === 5) {
        url =
          "/units?page=" +
          pageNumber +
          "&per_page=20&elearning=true&unit_type=4&isBackbuttonPressed=" +
          isBackButtonPressed;
      } else if (strand === 4) {
        url =
          "/units?page=" +
          pageNumber +
          "&per_page=20&foundational=true&unit_type=4&isBackbuttonPressed=" +
          isBackButtonPressed;
      } else {
        url =
          "/units?page=" +
          pageNumber +
          "&per_page=24&unit_type=" +
          strand +
          "&isBackbuttonPressed=" +
          isBackButtonPressed;
      }
    }
    return api
      .get(url, {
        withCredentials: true,
      })
      .then((response) => {
        return response.data;
      });
  },
  getFeaturedAssessments(strand) {
    let url = "/assessments?page=1&per_page=20&&featured=true&strand=" + strand;

    return api
      .get(url, {
        withCredentials: true,
      })
      .then((response) => {
        return response.data;
      });
  },
  getAssessments(pageNumber, strand, query, isBackButtonPressed) {
    let url = "";

    if (isBackButtonPressed === undefined) {
      isBackButtonPressed = false;
    }

    if (query) {
      url =
        "assessments/filter?page=" +
        pageNumber +
        "&per_page=24&strand=" +
        strand +
        (query ? query : "") +
        "&isBackbuttonPressed=" +
        isBackButtonPressed;
    } else {
      url =
        "/assessments?page=" +
        pageNumber +
        "&per_page=24&strand=" +
        strand +
        "&isBackbuttonPressed=" +
        isBackButtonPressed;
    }
    return api
      .get(url, {
        withCredentials: true,
      })
      .then((response) => {
        return response.data;
      });
  },
  getCustomFilters(strand, resource, query) {
    //Query is expected to have a preceding ampersand.

    let url = "";
    let strandtext = resource === "units" ? "unit_type" : "strand";
    let copyQuery = query || "";
    if (resource === "books")
      url = `${resource}?page=1&per_page=24${copyQuery}`;
    else {
      let eLearning = "",
        foundational = "",
        per_page = 24;
      if (strand === 5 && resource === "units") {
        eLearning = "&elearning=true";
        per_page = 20;
      } else {
        eLearning = "";
      }
      if (strand === 4 && resource === "units") {
        foundational = "&foundational=true";
        per_page = 20;
      }
      url = `${resource}/filter?page=1&per_page=${per_page}&${
        strand !== undefined ? strandtext : ""
      }=${
        strand !== undefined ? strand : ""
      }${copyQuery}${eLearning}${foundational}`;
    }

    return api
      .get(url, {
        withCredentials: true,
      })
      .then((response) => {
        return response.data;
      });
  },

  getNewCustomFilters() {
    let url = "/filters/custom_filters";
    return api
      .get(url, {
        withCredentials: false,
      })
      .then((response) => {
        return response.data;
      });
  },
  getUnitsDetail(id) {
    let url = "units/" + id + "/details";
    return api
      .get(url, {
        withCredentials: true,
      })
      .then((response) => {
        return response.data;
      });
  },
  getSingleUnitDetails(id) {
    let url = "units/" + id + "/details";
    return api
      .get(url, {
        withCredentials: true,
      })
      .then((response) => {
        return response.data;
      });
  },
  getUnitsDetailForFolder(id) {
    let url = "units/" + id + "/folder/details";
    return api
      .get(url, {
        withCredentials: true,
      })
      .then((response) => {
        return response.data;
      });
  },
  getLessonsDetail(id) {
    let url = "lessons/" + id + "/details";
    return api
      .get(url, {
        withCredentials: true,
      })
      .then((response) => {
        return response.data;
      });
  },

  getLessonsDetailForFolder(id) {
    let url = "lessons/" + id + "/folder/details";
    return api
      .get(url, {
        withCredentials: true,
      })
      .then((response) => {
        return response.data;
      });
  },
  getFilterOptions(strand, resourceType) {
    let url = "filters?strand=" + strand + "&type=" + resourceType;
    return api
      .get(url, {
        withCredentials: true,
      })
      .then((response) => {
        return response.data;
      });
  },
  postAddLessonToLibrary(lid) {
    let url = "libraries/lessons";
    return api
      .put(
        url,
        {
          _id: lid,
        },
        {
          withCredentials: true,
        }
      )
      .then((response) => {
        return response.data;
      });
  },
  postAddCorrelationToLibrary(cid) {
    let url = "libraries/correlations";
    return api
      .put(
        url,
        {
          _id: cid,
        },
        {
          withCredentials: true,
        }
      )
      .then((response) => {
        return response.data;
      });
  },
  saveNewUnit(name) {
    return api
      .post("units/", {
        name: name,
      })
      .then((response) => {
        return response;
      });
  },
  saveUnit(
    id,
    name,
    introduction,
    lesson_ids,
    image,
    assessment_ids,
    vocabulary,
    unit_doc
  ) {
    let obj = {};
    if (unit_doc) {
      obj = {
        name: name,
        introduction: introduction,
        lesson_ids: lesson_ids,
        image: image,
        copied_from: id,
        assessment_ids: assessment_ids,
        vocabulary: vocabulary,
        unit_doc: unit_doc,
      };
    } else {
      obj = {
        name: name,
        introduction: introduction,
        lesson_ids: lesson_ids,
        image: image,
        copied_from: id,
        assessment_ids: assessment_ids,
        vocabulary: vocabulary,
      };
    }
    return api.post("units/", obj).then((response) => {
      return response;
    });
  },
  updateUnitName(id, name) {
    let url = "units/" + id;
    return api
      .put(url, {
        name: name,
      })
      .then((response) => {
        return response;
      });
  },
  updateUnitIntroduction(id, introduction) {
    let url = "units/" + id;
    return api
      .put(url, {
        introduction: introduction,
      })
      .then((response) => {
        return response;
      });
  },
  updateUnitLessons(id, lesson_ids) {
    let url = "units/" + id;
    return api
      .put(url, {
        lesson_ids: lesson_ids,
      })
      .then((response) => {
        return response;
      });
  },
  updateUnit(id, name, introduction, lesson_ids, image) {
    let url = "units/" + id;
    return api
      .put(url, {
        name: name,
        introduction: introduction,
        lesson_ids: lesson_ids,
        image: image,
      })
      .then((response) => {
        return response;
      });
  },
  postAddTeachingResourceToLibrary(tid) {
    let url = "libraries/teachingResources";
    return api
      .put(
        url,
        {
          _id: tid,
        },
        {
          withCredentials: true,
        }
      )
      .then((response) => {
        return response.data;
      });
  },
  postAddUnitToLibrary(uid) {
    let url = "libraries/units";
    return api
      .put(
        url,
        {
          _id: uid,
        },
        {
          withCredentials: true,
        }
      )
      .then((response) => {
        return response.data;
      });
  },
  postAddBookToLibrary(uid) {
    let url = "libraries/books";
    return api
      .put(
        url,
        {
          _id: uid,
        },
        {
          withCredentials: true,
        }
      )
      .then((response) => {
        return response.data;
      });
  },
  postAddAssessmentToLibrary(uid) {
    let url = "libraries/assessments";
    return api
      .put(
        url,
        {
          _id: uid,
        },
        {
          withCredentials: true,
        }
      )
      .then((response) => {
        return response.data;
      });
  },
  getMyLibrary(tab, query, page = 1) {
    let url;
    if (tab === "folders") {
      url = "folders?page=" + page + "&per_page=100" + query;
      return api
        .get(url, {
          withCredentials: true,
        })
        .then((response) => {
          return response?.data?.rows.length
            ? { results: [...response.data.rows], total: response.data.count }
            : { results: [], total: 0 };
        });
    } else if (tab === "recents") {
      url = "libraries/recents?page=" + page + "&per_page=48" + query;
      return api
        .get(url, {
          withCredentials: true,
        })
        .then((response) => {
          return response?.data?.results
            ? response.data
            : { results: [...response.data], total: response.data.length };
        });
    } else {
      url =
        "libraries/" +
        (tab ? tab : "recents") +
        "?page=" +
        page +
        "&per_page=48" +
        query;
      return api
        .get(url, {
          withCredentials: true,
        })
        .then((response) => {
          return response?.data?.results
            ? response.data
            : { results: [...response.data], total: response.data.length };
        });
    }
  },
  getMyLibraryFilters(tab, strand = "", query = "", page = 1) {
    let url;
    if (strand && strand !== "books" && tab !== "books")
      url = `libraries/${tab}?page=${page}&${
        tab === "units" ? "unit_type" : "strand"
      }=${strand}&per_page=48${query}`;
    else url = `libraries/${tab}?page=${page}&per_page=48${query}`;
    return api
      .get(url, {
        withCredentials: true,
      })
      .then((response) => {
        return response.data;
      });
  },
  checkHasBookInLibrary() {
    let url = "libraries/books?page=1&per_page=1";
    return api
      .get(url, {
        withCredentials: true,
      })
      .then((response) => {
        return response.data;
      });
  },
  getMyLibraryRecents() {
    let url = "libraries/recents";
    return api
      .get(url, {
        withCredentials: true,
      })
      .then((response) => {
        return response.data;
      });
  },
  getScheduledTasks(view, myDate) {
    let url = "scheduledTasks?type=" + view + "&date=" + myDate;
    return api
      .get(url, {
        withCredentials: true,
      })
      .then((response) => {
        return response.data;
      });
  },
  getAnnotations(type, id) {
    let url = "annotations/" + type + "/" + id;
    return api
      .get(url, {
        withCredentials: true,
      })
      .then((response) => {
        return response.data;
      });
  },
  addAnnotation(itemType, annotationType, id, data) {
    return api
      .post(
        "annotations/" + itemType + "/" + annotationType,
        {
          item_id: id,
          data: data,
        },
        {
          withCredentials: true,
        }
      )
      .then((response) => {
        return response;
      });
  },
  editAnnotation(id, data) {
    return api
      .put(
        "annotations/" + id,
        {
          data: data,
        },
        {
          withCredentials: true,
        }
      )
      .then((response) => {
        return response;
      });
  },
  deleteAnnotation(id) {
    return api
      .delete("annotations/" + id, {
        withCredentials: true,
      })
      .then((response) => {
        return response;
      });
  },
  postScheduledTasks(myDate, id, type) {
    let url = "scheduledTasks";
    return api
      .post(
        url,
        {
          day: myDate,
          item_id: id,
          item_type: type,
        },
        {
          withCredentials: true,
        }
      )
      .then((response) => {
        return response;
      });
  },
  getCustomUnits(id, query, page = 1) {
    let url = "libraries/units?page=" + page + "&per_page=20&lesson_id=" + id;
    if (query) {
      url = url + "&search=" + query;
    }
    return api
      .get(url, {
        withCredentials: true,
      })
      .then((response) => {
        return response.data;
      });
  },
  orderScheduledTasks(obj) {
    let url = "scheduledTasks/order";
    let temp = {
      scheduled_tasks: obj,
    };
    return api
      .put(url, temp, {
        withCredentials: true,
      })
      .then((response) => {
        return response.data;
      });
  },
  getUsers(
    userType,
    search,
    districtId = "",
    page = 1,
    myStudents,
    itemType,
    itemId,
    sharedFolder
  ) {
    let url;
    if (search === "") {
      if (!sharedFolder) {
        url = "users?page=" + page + "&per_page=50000&userType=" + userType;
      } else {
        url =
          "users?page=" +
          page +
          "&per_page=50000&loggedInUserType=" +
          localStorage.getItem("user_type") +
          "&userType=" +
          userType;
      }
    } else {
      if (!sharedFolder) {
        url =
          "users?page=" +
          page +
          "&per_page=20&userType=" +
          userType +
          "&search=" +
          search;
      } else {
        url =
          "users?page=" +
          page +
          "&per_page=50000&loggedInUserType=" +
          localStorage.getItem("user_type") +
          "&&userType=" +
          userType +
          "&search=" +
          search;
      }
    }

    if (districtId !== "") {
      url +=
        "&district=" +
        (districtId == 0 ? localStorage.district_id : districtId);
    }
    if (myStudents) {
      url += "&my_students=true";
    }
    if (itemType && itemId) {
      url += "&item_type=" + itemType + "&item_id=" + itemId;
    }

    if (sharedFolder) {
      url += "&sharedFolder=true";
      url += "&userId=" + localStorage.user_id;
    } else {
      url += "&sharedFolder=false";
    }
    return api
      .get(url, {
        withCredentials: true,
      })
      .then((response) => {
        return response.data;
      });
  },

  putUser(user_id, obj) {
    let url = "users/" + user_id;
    return api
      .put(url, obj, {
        withCredentials: true,
      })
      .then((response) => {
        return response.data;
      });
  },
  putLicense(user_id, obj) {
    let url = "users/" + user_id;
    return api
      .put(url, obj, {
        withCredentials: true,
      })
      .then((response) => {
        return response.data;
      });
  },
  // update license for existing user
  updateLicense(userData) {
    let url = "users/license/" + userData.uid;
    return api
      .put(url, userData, {
        withCredentials: true,
      })
      .then((response) => {
        return response.data;
      });
  },

  getLessons(pageNumber, strand, query = "", isBackButtonPressed = false) {
    let url;
    let copyQuery = query || "";

    if (isBackButtonPressed === undefined) {
      isBackButtonPressed = false;
    }

    if (query) {
      url =
        "lessons/filter?page=" +
        pageNumber +
        "&per_page=24&strand=" +
        strand +
        (copyQuery ? copyQuery : "");
    } else if (strand) {
      url =
        "lessons/filter?page=" + pageNumber + "&per_page=24&strand=" + strand;
    } else {
      url = "/lessons?page=" + pageNumber + "&per_page=24";
    }
    return api
      .get(url, {
        withCredentials: true,
      })
      .then((response) => {
        return response.data;
      });
  },

  getAllLessons(pageNumber, strand, query = "", isBackButtonPressed = false) {
    let url;
    let copyQuery = query || "";

    if (isBackButtonPressed === undefined) {
      isBackButtonPressed = false;
    }

    if (query) {
      url =
        "lessons/getAllLessons?page=" +
        pageNumber +
        "&per_page=24&strand=" +
        strand +
        (copyQuery ? copyQuery : "");
    } else if (strand) {
      url =
        "lessons/getAllLessons?page=" +
        pageNumber +
        "&per_page=24&strand=" +
        strand;
    } else {
      url = "/lessons?page=" + pageNumber + "&per_page=24";
    }
    return api
      .get(url, {
        withCredentials: true,
      })
      .then((response) => {
        return response.data;
      });
  },

  getLesssonsForCustomUnit(id) {
    let url = "lessons/" + id + "/detailsForCustomUnit";
    return api
      .get(url, {
        withCredentials: true,
      })
      .then((response) => {
        return response.data;
      });
  },
  getSchoolDistrict(id) {
    let url = "schoolDistricts/" + id;
    return api
      .get(url, {
        withCredentials: true,
      })
      .then((response) => {
        return response.data;
      });
  },
  getDistricts(search, page = 1, perPage = 20) {
    let url;
    if (search === "")
      url = "schoolDistricts?page=" + page + "&per_page=" + perPage;
    else
      url =
        "schoolDistricts?page=" +
        page +
        "&per_page=" +
        perPage +
        "&search=" +
        search;
    return api
      .get(url, {
        withCredentials: true,
      })
      .then((response) => {
        return response.data;
      });
  },
  deleteUser(id) {
    let url = "users/" + id;
    return api
      .delete(url, {
        withCredentials: true,
      })
      .then((response) => {
        return response.data;
      });
  },
  deleteStudentUser(id) {
    let url = "users/deleteStudent/" + id;
    return api
      .delete(url, {
        withCredentials: true,
      })
      .then((response) => {
        return response.data;
      });
  },
  deleteDistrict(id) {
    let url = "schoolDistricts/" + id;
    return api
      .delete(url, {
        withCredentials: true,
      })
      .then((response) => {
        return response.data;
      });
  },
  putDistrict(district_id, districtDetails) {
    let url = "schoolDistricts/" + district_id;
    return api
      .put(url, districtDetails, {
        withCredentials: true,
      })
      .then((response) => {
        return response.data;
      });
  },
  putNewCountLicense(district_id, license_data) {
    let url = "schoolDistricts/editlicense/" + district_id;
    return api
      .put(url, license_data, {
        withCredentials: true,
      })
      .then((response) => {
        return response.data;
      });
  },
  postUser(userObj) {
    let url = "users";
    return api
      .post(url, userObj, {
        withCredentials: true,
      })
      .then((response) => {
        return response.data;
      });
  },
  postDistrict(districtDetails) {
    let url = "schoolDistricts/";
    return api
      .post(url, districtDetails, {
        withCredentials: true,
      })
      .then((response) => {
        return response.data;
      });
  },
  postImportUsers(userType, districtId, userData) {
    let url =
      "users/import?user_type=" + userType + "&district_id=" + districtId;
    return api
      .post(url, userData, {
        withCredentials: true,
        headers: {
          "Content-Type": "text/plain",
        },
      })
      .then((response) => {
        return response.data;
      });
  },
  postFolder(folderObj) {
    let url = "folders";
    return api
      .post(url, folderObj, {
        withCredentials: true,
      })
      .then((response) => {
        return response.data;
      });
  },
  getMyDetails() {
    let url = "users/me";
    return api
      .get(url, {
        withCredentials: true,
      })
      .then((response) => {
        return response.data;
      });
  },
  getResourceDetails(id) {
    let url = "teachingResources/" + id + "/details";
    return api
      .get(url, {
        withCredentials: true,
      })
      .then((response) => {
        return response.data;
      });
  },
  getResourceDetailsForFolder(id) {
    let url = "teachingResources/" + id + "/folder/details";
    return api
      .get(url, {
        withCredentials: true,
      })
      .then((response) => {
        return response.data;
      });
  },
  getBookDetails(id) {
    let url = "books/" + id + "/details";
    return api
      .get(url, {
        withCredentials: true,
      })
      .then((response) => {
        return response.data;
      });
  },
  getBookDetailsForFolder(id) {
    let url = "books/" + id + "/folder/details";
    return api
      .get(url, {
        withCredentials: true,
      })
      .then((response) => {
        return response.data;
      });
  },
  getBooks(pageNumber, query, isBackButtonPressed, queryPreviewBooks) {
    let url;
    if (isBackButtonPressed === undefined) {
      isBackButtonPressed = false;
    }
    if (queryPreviewBooks === undefined) {
      queryPreviewBooks = false;
    }
    if (query) {
      url =
        "books?page=" +
        pageNumber +
        "&per_page=24" +
        (query ? query : "") +
        "&isBackbuttonPressed=" +
        isBackButtonPressed +
        "&preview=" +
        queryPreviewBooks;
    } else {
      url =
        "books?page=" +
        pageNumber +
        "&per_page=24&isBackbuttonPressed=" +
        isBackButtonPressed +
        "&preview=" +
        queryPreviewBooks;
    }
    if (url.includes("search")) {
      let quarr = url.split("search=");
      let anotherquarr = quarr[1].split("&");
      if (anotherquarr[0] !== "") {
        return api
          .get(url, {
            withCredentials: true,
            headers: {
              "Content-Type": "text/plain",
            },
          })
          .then((response) => {
            return response.data;
          });
      }
    } else {
      return api
        .get(url, {
          withCredentials: true,
          headers: {
            "Content-Type": "text/plain",
          },
        })
        .then((response) => {
          return response.data;
        });
    }
  },

  getBookPreviewStatus(bookId) {
    let url = "books/" + bookId + "/preview";
    return api
      .get(url, {
        withCredentials: true,
      })
      .then((response) => {
        return response.data.preview;
      })
      .catch((err) => {
        return err;
      });
  },

  getFolders(
    pageNumber,
    searchString,
    queryString,
    is_lite,
    sortByDate,
    sortByName
  ) {
    let url;
    if (sortByDate === undefined) {
      sortByDate = "";
    }
    if (sortByName === undefined) {
      sortByName = "";
    }
    if (searchString === "" && sortByDate === "" && sortByName === "") {
      url =
        "folders?page=" +
        pageNumber +
        "&per_page=30" +
        queryString +
        "&is_lite=" +
        is_lite;
    } else if (searchString !== "") {
      url =
        "folders?page=" +
        pageNumber +
        "&per_page=30&search=" +
        searchString +
        queryString +
        "&is_lite=" +
        is_lite;
      if (sortByDate !== "") {
        url += "&orderByDate=" + sortByDate;
      } else if (sortByName !== "") {
        url += "orderByName=" + sortByName;
      }
    } else if (sortByDate !== "") {
      url =
        "folders?page=" +
        pageNumber +
        "&per_page=30&orderByDate=" +
        sortByDate +
        queryString +
        "&is_lite=" +
        is_lite;
    } else if (sortByName !== "") {
      url =
        "folders?page=" +
        pageNumber +
        "&per_page=30&orderByName=" +
        sortByName +
        queryString +
        "&is_lite=" +
        is_lite;
    }

    return api
      .get(url, {
        withCredentials: true,
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  },
  getGroups(search) {
    let url = "groups";
    if (search) {
      url = "groups?search=" + search;
    }

    return api
      .get(url, {
        withCredentials: true,
      })
      .then((response) => {
        return response.data;
      });
  },
  getUser(id) {
    let url = "users/" + id;
    return api
      .get(url, {
        withCredentials: true,
      })
      .then((response) => {
        return response.data;
      });
  },
  studentLoginWithGoogle(email) {
    let url = "users/googlesignin/";
    return api
      .post(
        url,
        {
          email: email,
        },
        {
          withCredentials: true,
        }
      )
      .then((response) => {
        return response;
      });
  },
  getAssessmentDetails(id) {
    let url = "assessments/" + id + "/details";
    return api
      .get(url, {
        withCredentials: true,
      })
      .then((response) => {
        return response.data;
      });
  },
  getAssessmentDetailsForFolder(id) {
    let url = "assessments/" + id + "/folder/details";
    return api
      .get(url, {
        withCredentials: true,
      })
      .then((response) => {
        return response.data;
      });
  },
  getCorrelationDetails(id) {
    let url = "correlations/" + id + "/details";
    return api
      .get(url, {
        withCredentials: true,
      })
      .then((response) => {
        return response.data;
      });
  },
  postGroup(groupObj) {
    let url = "groups";
    return api
      .post(url, groupObj, {
        withCredentials: true,
      })
      .then((response) => {
        return response.data;
      });
  },
  getCustomStandards(query) {
    let url;
    if (query !== "") {
      url = "units/custom/standards?" + (query ? query : "");
    } else {
      url = "units/custom/standards";
    }
    return api
      .get(url, {
        withCredentials: true,
      })
      .then((response) => {
        return response.data;
      });
  },
  getGroupById(gid) {
    let url = "groups/" + gid + "/details";
    return api
      .get(url, {
        withCredentials: true,
      })
      .then((response) => {
        return response.data;
      });
  },
  putGroup(groupId, groupNameObj) {
    let url = "groups/" + groupId;
    return api
      .put(url, groupNameObj, {
        withCredentials: true,
      })
      .then((response) => {
        return response.data;
      });
  },
  putGroupImportStudents(groupId, groupData) {
    let url = "groups/" + groupId + "/import-students";
    return api
      .put(url, groupData, {
        withCredentials: true,
        headers: {
          "Content-Type": "text/plain",
        },
      })
      .then((response) => {
        return response.data;
      });
  },
  addUserToGroup(groupId, Obj) {
    let url = "groups/" + groupId + "/users";
    return api
      .put(url, Obj, {
        withCredentials: true,
      })
      .then((response) => {
        return response.data;
      });
  },
  removeUserFromGroup(groupId, Obj) {
    let url = "groups/" + groupId + "/users";
    return api
      .delete(
        url,
        {
          data: Obj,
        },
        {
          withCredentials: true,
        }
      )
      .then((response) => {
        return response.data;
      });
  },
  getRecommendedForYou() {
    let url = "teachingResources/recommended";
    return api
      .get(url, {
        withCredentials: true,
      })
      .then((response) => {
        return response.data;
      });
  },
  updateFirstTimeUserLogin(username) {
    let url = "users/updateFirstTimeLoginState";
    return api
      .post(
        url,
        {
          username: username,
        },
        {
          withCredentials: true,
        }
      )
      .then((response) => {
        return response.data;
      });
  },
  logout() {
    let url = "users/logout";
    return api
      .post(url, {
        withCredentials: true,
      })
      .then((response) => {
        return response.data;
      });
  },
  deleteGroup(id) {
    let url = "groups/" + id;
    return api
      .delete(url, {
        withCredentials: true,
      })
      .then((response) => {
        return response.data;
      });
  },
  deleteFolders(id) {
    let url = "folders/" + id;
    return api
      .delete(url, {
        withCredentials: true,
      })
      .then((response) => {
        return response;
      });
  },
  putFolders(id, folderName) {
    let url = "folders/" + id;
    return api
      .put(
        url,
        { folderName },
        {
          withCredentials: true,
        }
      )
      .then((response) => {
        return response;
      });
  },
  reviewToken(token) {
    let url = "users/" + token + "/review-token";
    return api
      .get(url, {
        withCredentials: true,
      })
      .then((response) => {
        return response;
      });
  },
  recoverPassword(email) {
    let url = "users/recovery";
    return api
      .post(
        url,
        {
          username: email,
        },
        {
          withCredentials: true,
        }
      )
      .then((response) => {
        return response.data;
      });
  },
  adminResetPassword(user_id) {
    let url = "users/" + user_id + "/reset-password";
    let resetObject = {};
    return api
      .put(url, resetObject, {
        withCredentials: true,
      })
      .then((response) => {
        return response.data;
      });
  },
  resetPassword(password, token) {
    let url = "users/reset";
    return api
      .put(
        url,
        {
          password: password,
          token: token,
        },
        {
          withCredentials: true,
        }
      )
      .then((response) => {
        return response.data;
      });
  },
  getUserAssignments(
    user_id,
    pageNumber,
    isComplete,
    isDue,
    date,
    teacher_ids
  ) {
    let url;
    if (teacher_ids) {
      let query = "";
      for (let i = 0; i < teacher_ids.length; i++) {
        query = query + "&teacher_id=" + teacher_ids[i];
      }
      url =
        "users/" +
        user_id +
        "/assignments?page=" +
        pageNumber +
        "&per_page=10&is_complete=" +
        isComplete +
        "&is_due=" +
        isDue +
        "&date=" +
        date +
        query;
    } else {
      url =
        "users/" +
        user_id +
        "/assignments?page=" +
        pageNumber +
        "&per_page=10&is_complete=" +
        isComplete +
        "&is_due=" +
        isDue +
        " &date=" +
        date;
    }
    return api
      .get(url, {
        withCredentials: true,
        headers: {
          "Content-Type": "text/plain",
        },
      })
      .then((response) => {
        return response.data;
      });
  },
  getHomeResources(grade) {
    let url = grade ? "/featured/resources?" + grade : "/featured/resources";
    return api
      .get(url, {
        withCredentials: false,
      })
      .then((response) => {
        return response.data;
      });
  },
  globalSearch(pageNumber, searchString, queryString, is_lite) {
    let url;
    // Add is_lite as a query parameter here. Gets passed to api call.
    // server/features/search/SearchRoute.js
    if (searchString === "") {
      url =
        "search?page=" +
        pageNumber +
        "&per_page=30" +
        queryString +
        "&is_lite=" +
        is_lite;
    } else {
      url =
        "search?page=" +
        pageNumber +
        "&per_page=30&search=" +
        searchString +
        queryString +
        "&is_lite=" +
        is_lite;
    }
    return api
      .get(url, {
        withCredentials: true,
      })
      .then((response) => {
        return response.data;
      });
  },
  pageSpecificSearch(pageNumber, searchString, queryString, is_lite) {
    let url;
    // Add is_lite as a query parameter here. Gets passed to api call.
    // server/features/search/SearchRoute.js
    if (searchString === "") {
      url =
        "search?page=" +
        pageNumber +
        "&per_page=1000" +
        queryString +
        "&is_lite=" +
        is_lite;
    } else {
      url =
        "search?page=" +
        pageNumber +
        "&per_page=30&search=" +
        searchString +
        queryString +
        "&is_lite=" +
        is_lite;
    }
    return api
      .get(url, {
        withCredentials: true,
      })
      .then((response) => {
        return response.data;
      });
  },
  getUserDetailsById(user_id) {
    let url = "users/" + user_id + "/details";
    return api
      .get(url, {
        withCredentials: true,
      })
      .then((response) => {
        return response.data;
      });
  },
  completeAssignment(asst_id, is_complete) {
    let url = "assignments/" + asst_id + "/complete";
    return api
      .put(
        url,
        {
          is_complete: is_complete,
        },
        {
          withCredentials: true,
        }
      )
      .then((response) => {
        return response.data;
      });
  },
  postAssignment(sid, assign_object) {
    let url = "users/" + sid + "/assignment";
    return api
      .post(url, assign_object, {
        withCredentials: true,
      })
      .then((response) => {
        return response.data;
      });
  },
  postFolderAssignment(fid, assign_object) {
    let url = "folders/" + fid + "/assignment";
    return api
      .post(url, assign_object, {
        withCredentials: true,
      })
      .then((response) => {
        return response.data;
      });
  },
  getGoalTypes() {
    let url = "users/trial/goal-types";
    return api
      .get(url, {
        withCredentials: true,
      })
      .then((response) => {
        return response.data;
      });
  },
  getReferralTypes() {
    let url = "users/trial/referral-types";
    return api
      .get(url, {
        withCredentials: true,
      })
      .then((response) => {
        return response.data;
      });
  },
  postTrailUser(object) {
    let url = "users/trial";
    return api
      .post(url, object, {
        withCredentials: true,
      })
      .then((response) => {
        return response;
      });
  },
  postImportStudents(userData) {
    let url = "users/importStudent";
    return api
      .post(url, userData, {
        withCredentials: true,
      })
      .then((response) => {
        return response;
      });
  },

  fetchGoogleClasses(googleData) {
    let url = "users/googleClass";
    return api
      .post(url, googleData[0], {
        withCredentials: true,
      })
      .then((response) => {
        return response;
      });
  },

  fetchGoogleClassStudentByCourse(courseData) {
    let url = "users/courseStudent";
    return api
      .post(url, courseData, {
        withCredentials: true,
      })
      .then((response) => {
        return response;
      });
  },

  validateUserSessionByUUID(uuid) {
    //validateSession
    let url = "users/validateSession/" + uuid;
    return api
      .get(url, {
        withCredentials: true,
      })
      .then((response) => {
        return response.data;
      });
  },

  wordDefinition(word) {
    let url = "/books/definition?word=" + word;
    return api
      .get(url, {
        withCredentials: true,
      })
      .then((response) => {
        return response.data;
      });
  },
  emailValidation(email) {
    let url = "users/trial/valid-email?email=" + email;
    return api
      .get(url, {
        withCredentials: true,
      })
      .then((response) => {
        return response;
      });
  },
  resetUserPassword(user_id, isPicturePassword) {
    let url = "users/" + user_id + "/reset-password";
    let resetObject = {
      picture_password: isPicturePassword,
    };
    return api
      .put(url, resetObject, {
        withCredentials: true,
      })
      .then((response) => {
        return response.data;
      });
  },
  removeLibraryItem(item, id) {
    let obj = {
      _id: id,
    };
    let url = "libraries/" + item;
    return api
      .delete(
        url,
        {
          data: obj,
        },
        {
          withCredentials: true,
        }
      )
      .then((response) => {
        return response;
      });
  },
  removeFolderResource(item, fid) {
    //let url = 'assessments/' + id + '/details';
    let url =
      "folders/" + fid + "/" + item.type + "/" + (item?.id ?? item?._id);
    let obj = {
      _id: item.id,
    };
    return api
      .delete(
        url,
        {
          data: obj,
        },
        {
          withCredentials: true,
        }
      )
      .then((response) => {
        return response;
      });
  },
  removePlannerItem(id) {
    let url = "scheduledTasks/" + id;
    return api
      .delete(url, {
        withCredentials: true,
      })
      .then((response) => {
        return response;
      });
  },
  addUnitsToLesson(id, unitIds) {
    let url = "lessons/" + id + "/units";
    return api
      .put(
        url,
        {
          unit_ids: unitIds,
        },
        {
          withCredentials: true,
        }
      )
      .then((response) => {
        return response;
      });
  },
  /***
   * Gets all bookmarks for the given book
   * @param id is the id of the book
   * @returns {*} a promise with the response from the server: [{_id, user_id, book_id, page}...]
   */
  getBookmarks(id) {
    let url = "books/" + id + "/bookmarks";
    return api
      .get(url, {
        withCredentials: true,
      })
      .then((response) => {
        return response;
      });
  },
  /***
   * Delete the bookmark by it's id
   * @param id is the id of the bookmark
   * @returns {*} a promise with the result
   */
  deleteBookmark(id) {
    let url = "books/bookmark/" + id;
    return api
      .delete(url, {
        withCredentials: true,
      })
      .then((response) => {
        return response;
      });
  },
  /***
   * Adds a bookmark to a book
   * the userid is inherit from the session
   * @param id is the id of the book
   * @param page is the page number
   * @returns {*} a promise with the result
   */
  addBookmark(id, page) {
    let url = "books/" + id + "/bookmark";
    return api
      .post(
        url,
        {
          page: page,
        },
        {
          withCredentials: true,
        }
      )
      .then((response) => {
        return response;
      });
  },
  setReaderResponse(question_id, user_response) {
    let url = `/books/e-learner/response`;
    return api
      .post(
        url,
        {
          question_id: question_id,
          user_response: user_response,
        },
        {
          withCredentials: true,
        }
      )
      .then((response) => {
        return response.data;
      });
  },
  setReaderReview(book_id, review_text) {
    let url = `/books/e-learner/review`;
    return api
      .post(
        url,
        {
          book_id: book_id,
          review_text: review_text,
        },
        {
          withCredentials: true,
        }
      )
      .then((response) => {
        return response.data;
      });
  },
  getSkills(id) {
    let url = "books/" + id + "/skills";
    return api
      .get(url, {
        withCredentials: true,
      })
      .then((response) => {
        return response;
      });
  },
  async findSchoolByEmail(email) {
    let url = "schoolDistricts/by_email";
    try {
      let response = await api.post(
        url,
        {
          email,
        },
        {
          withCredentials: true,
        }
      );
      return response;
    } catch (error) {
      console.warn(`${url} api error: ${error}`);
      return error;
    }
  },
  async findSchoolLite(email) {
    let url = "schoolDistricts/lite";
    try {
      let response = await api.post(
        url,
        {
          email,
        },
        {
          withCredentials: true,
        }
      );
      return response;
    } catch (error) {
      console.warn(`${url} api error: ${error}`);
      return error;
    }
  },
  async registerNewUser(data) {
    let url = "users/signup";
    let response;
    try {
      response = await api.post(url, data, {
        withCredentials: true,
      });
      return response;
    } catch (error) {
      return error;
    }
  },
  async getDistrictLicenses({ _id }) {
    let url = `schoolDistricts/${_id}/licenses`;
    let response;
    try {
      response = await api.get(url, {
        withCredentials: true,
      });
      return response;
    } catch (error) {
      return error;
    }
  },
  async getLicenseAvailibility({ _id, type, lid }) {
    if (lid || type) {
      let url = `schoolDistricts/${_id}/licenses/${lid ? lid : type}`;
      let response;
      try {
        response = await api.get(url, {
          withCredentials: true,
        });
        return response;
      } catch (error) {
        return error;
      }
    } else {
      return false;
    }
  },
  async getDistrictLicensesOnlyUnlimited({ _id }) {
    let url = `schoolDistricts/${_id}/licenses_U`;
    let response;
    try {
      response = await api.get(url, {
        withCredentials: true,
      });
      return response;
    } catch (error) {
      return error;
    }
  },
  async addDistrictLicenses({ _id, licenses_count, licenses_type, date = {} }) {
    let url = `schoolDistricts/${_id}/licenses`;
    let response;
    try {
      response = await api.post(
        url,
        { licenses_type, licenses_count, date },
        {
          withCredentials: true,
        }
      );
      return response;
    } catch (error) {
      return error;
    }
  },
  async byBookOnMackin() {
    let url = `users/mackin/1`;
    let response;
    try {
      response = await api.get(url, {
        withCredentials: true,
      });
      return response;
    } catch (error) {
      return error;
    }
  },
  async getMySchoolDistrict() {
    let url = `schoolDistricts/district/my_district`;
    let response;
    try {
      response = await api.get(url, {
        withCredentials: true,
      });
      return response;
    } catch (error) {
      return error;
    }
  },
  getItemsInFolder(id) {
    let url = "libraries/folders/" + id;
    return api
      .get(url, {
        withCredentials: true,
      })
      .then((response) => {
        return response.data;
      });
  },
  validateSSOLogin(sos_user_code) {
    return api
      .post("/users/sosAuthLogin", {
        sos_user_code: sos_user_code,
      })
      .then((response) => {
        return response;
      });
  },
  samlJSON(item_id, item_type) {
    let url = "/users/samlp?item_id=" + item_id + "&item_type=" + item_type;
    return api.get(url).then((response) => {
      return response;
    });
  },
  deleteUserAssignment(assignment_id, student_id) {
    let url = "assignments/deleteUserAssignment";
    return api
      .post(url, {
        assignment_id: assignment_id,
        student_id: student_id,
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log("err");
      });
  },
  getAppendicesDetailsbyId(unit_id) {
    let url = "/teachingResources/appendices";
    return api
      .post(url, {
        unit_id: unit_id,
      })
      .then((response) => {
        return response.data;
      });
  },
  validateClassromFileLink(file) {
    let url = "/lessons/validateGClassroomShareLink";
    return api
      .post(url, {
        file: file,
      })
      .then((response) => {
        return response;
      });
  },
  bulkAddToLibrary(items) {
    let url = "/libraries/bulk_add";
    return api
      .put(url, {
        swf_items: items,
      })
      .then((response) => {
        return response;
      });
  },
  bulkRemoveFromLibrary(items) {
    let url = "/libraries/bulk_rmv";
    return api
      .put(
        url,
        {
          swf_items: items,
        },
        {
          withCredentials: true,
        }
      )
      .then((response) => {
        return response.data;
      });
  },
  bulkAddToFolder(fid, assign_object) {
    let url = "folders/" + fid + "/assignment";
    return api
      .post(url, assign_object, {
        withCredentials: true,
      })
      .then((response) => {
        return response.data;
      });
  },
  bulkRemoveFromFolder(items) {
    let url = "folders/bulk_delete";
    return api
      .post(url, items, {
        withCredentials: true,
      })
      .then((response) => {
        return response.data;
      });
  },
  shareFolder(fid, users, shared_url) {
    let url = `folders/${fid}/share_with_users`;

    let postObj = {
      share_with_users: users,
      shared_url: shared_url,
    };

    return api
      .post(url, postObj, {
        withCredentials: true,
      })
      .then((response) => {
        return response.data;
      });
  },
  saveToFolder(id) {
    let url = `folders/${id}/save_shared_folder_to_library`;

    return api
      .post(
        url,
        {},
        {
          withCredentials: true,
        }
      )
      .then((response) => {
        return response.data;
      });
  },
  removeSharedByUser(id) {
    let url = `folders/${id}/remove_shared_by_user`;

    return api
      .delete(url, {
        withCredentials: true,
      })
      .then((response) => {
        return response.data;
      });
  },
  getFolderNotifications() {
    let url = `folders/notifications`;

    return api
      .get(url, {
        withCredentials: true,
      })
      .then((response) => {
        return response.data;
      });
  },
  dismissFolderNotifications(folderIds) {
    let url = `folders/notifications`;

    return api
      .post(
        url,
        {
          folderIds,
        },
        {
          withCredentials: true,
        }
      )
      .then((response) => {
        return response.data;
      });
  },
  testforLibraryGlobal(id, query) {
    let page = 1;
    let per_page = 48;
    let url =
      `libraries/${id}/search?page=${page}&per_page=${per_page}&search=` +
      query;
    return api
      .get(url, {
        withCredentials: true,
      })
      .then((response) => {
        return response.data;
      });
  },
  libraryGlobalSearch(page = 1, per_page = 48, query = "") {
    const searchQuery = query ? "&search=" + query : "";
    const url =
      `libraries/search?page=${page}&per_page=${per_page}` + searchQuery;
    return api
      .get(url, {
        withCredentials: true,
      })
      .then((response) => {
        return response.data;
      });
  },

  async logBookUserActivity(bookId) {
    let url = "books/logUserActivity";
    return api
      .post(
        url,
        {
          bookId: bookId,
        },
        {
          withCredentials: true,
        }
      )
      .then((response) => {
        return response.data;
      });
  },
};

export default authApi;

import React, {Component} from 'react';
import {browserHistory} from 'react-router';
import * as actions from '../../redux/actions'
import {connect} from 'react-redux'
import {OverlayTrigger, Tooltip} from 'react-bootstrap';
import publishUpArrow from '../../assets/images/projecticon-01.png'


class SchoolwideFooterItem extends Component{
    constructor(props){
        super(props);
        this.state = {
            amIactive : false
        };
    }

    componentWillReceiveProps(next){
        if(next.activeItemName === next.pageName){
            this.setState({
                amIactive : true
            });
        }
        else{
            this.setState({
                amIactive : false
            });
        }
    }

    handleClick(){
        // this.props.selectOneItem(this.props.pageName);
        // let target = this.props.pageName;
        // browserHistory.push("/"+target);
        //window.open('https://www.xanedu.com','_blank');
       console.log("Test handle click");
       //window.open("/schoolwidefun/api/v1/users/samlpFooter","_blank")
        window.open("/schoolwidefun/api/v1/users/redirectSaml","_blank")
    }


    render(){

        return this.props.lite === 'false' && localStorage.user_type !== 'Student' && localStorage.user_type !== 'Text Only' ?
            <div onClick={() => this.handleClick()} className={this.state.amIactive ? "footer-btn-container active-footer-btn" : "footer-btn-container"}>
                <div  className="footer-schoolwide-image">
                    <img src={publishUpArrow} className="footer-schoolwide-publishUpArrowIcon"/>
                </div>
                <div className="footer-schoolwide-text" style={{"width":"62px"}}>
                    PROJECTS
                </div>
            </div>
            :
            
            <OverlayTrigger trigger="click"
                            rootClose
                            placement="top"
                            overlay={
                                this.props.lite === 'true' &&
                                <Tooltip id="lite-user-planner-popup">
                                    This feature is only available with a Fundamentals Unlimited subscription. <br/> <a href="https://www.schoolwide.com/contact.php?t=nothing" target="_blank"> Contact us</a> for more information.
                                </Tooltip>
                            }
            >
                <div className={"footer-btn-container disabled-footer-btn"} data-toggle="tooltip" data-html="true" title={this.props.text}>
                    <div  className="footer-schoolwide-image">
                        <div className={"footer-icon icon-icon-" + this.props.icon} style={{color:this.state.amIactive?'#009BBD':'#FFFFFF'}}/>
                    </div>
                    <div className="footer-schoolwide-text">
                        {this.props.text}
                    </div>
                </div>
            </OverlayTrigger>;
    }
}

export default connect(null,actions)(SchoolwideFooterItem)